import React from "react"

import "./Footer.css"

interface Props {}

export const Footer: React.FunctionComponent<Props> = () => {
  return (
    <footer>
      © {new Date().getFullYear()}, Built with
      {` `}
      <a href="https://www.gatsbyjs.org">Gatsby</a>
    </footer>
  )
}
