import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Footer } from "../components/Footer/Footer"
import SEO from "../components/seo"
import Note from "../components/Note/Note"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { mdx } = data
  const { frontmatter } = mdx
  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <div className="notes-post-container">
        <Note {...mdx} />
      </div>
      <Footer />
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        slug
      }
    }
  }
`
