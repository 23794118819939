import React from "react"
import { Link } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import "./Note.css"

export interface NoteData {
  id: string
  body?: string
  frontmatter: {
    title: string
    date: string
    slug: string
    draft?: boolean
  }
}

const Note: React.FunctionComponent<NoteData> = ({ body, frontmatter }) => {
  return (
    <article className="note">
      <h1>
        <Link to={frontmatter.slug}>{frontmatter.title}</Link>
      </h1>
      <div className="timestamp">{frontmatter.date}</div>
      <div className="note-content">
        <MDXRenderer>{body}</MDXRenderer>
      </div>
    </article>
  )
}

export default Note
